import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
const brandVariant = definePartsStyle((props) => {
    const { colorScheme: c } = props // extract colorScheme from component props

    return {
      tab: {
        border: '2px solid',
        borderColor: mode(`${c}.300`, `${c}.600`)(props),
        /* borderRadius: 'md', */
        _selected: {
          bg: mode(`${c}.300`, `${c}.600`)(props),
          color: 'white',
        },
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      tablist: {
        gap: '4'
      },
      tabpanel: {
        border: '2px solid',
        borderColor: mode(`${c}.300`, `${c}.600`)(props),
        /* borderRadius: 'md', */
        p: '8',
        shadow: 'md',
        bg: 'gray.100'
      },
    }
})

// export the component theme
const Tabs = defineMultiStyleConfig({ 
    variants: {
        brand: brandVariant
    }
 })

export default Tabs