import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const ghost = defineStyle((props) => {
    const { colorScheme: c } = props // extract colorScheme from component props

    return {
        px: '8',
        py: '6',
        fontSize: '1em',
        fontWeight: 'semibold',
        _hover: {
            bg: `${c}.600`,
            color: 'white'
        },
        _active: {
            bg: `${c}.400`,
            color: 'white'
        }
    }
})

const solid = defineStyle({
    px: '12',
    py: '6',
    fontSize: '1em',
    fontWeight: 'semibold',
})

const outline = defineStyle({
    px: '12',
    py: '6',
    fontSize: '1em',
    fontWeight: 'semibold',
})

const Button = defineStyleConfig({
  variants: { ghost, solid, outline },
})

export default Button;