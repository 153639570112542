import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import './i18n';
import MainLayout from './Layouts/MainLayout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MotionConfig transition={{ duration: .25, bounce: 0.1 }} >
        <AnimatePresence>
            <ChakraProvider theme={theme}>
                <Suspense fallback={<></>}>
                  <MainLayout>
                    <App />
                  </MainLayout>
                </Suspense>
            </ChakraProvider>
        </AnimatePresence>
    </MotionConfig>
  </React.StrictMode>
);