import { Box, Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Heading, Icon, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineArrowUp, AiOutlineFacebook, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineMenu } from 'react-icons/ai'
import ScrollToTop from "react-scroll-to-top";
import logo from '../images/logotipo.png'

const MainLayout = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [links] = useState([
        {
            title: 'home',
            link: '/#home',
            active: '/zxy'
        },
        {
            title: 'Beneficios',
            link: '/#beneficios',
            active: '/zxy'
        },
        {
            title: 'about-us',
            link: '/#about-us',
            active: '/zxy'
        },
        {
            title: 'services',
            link: '/#services',
            active: '/zxy'
        },
        {
            title: 'contact',
            link: '/#contact',
            active: '/zxy'
        },
    ])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <Flex direction='column' w='100vw' overflowX='hidden'>
            <Flex pos='relative' direction='column' w='full'>
                <Flex zIndex='1' direction={{ base: 'column', xl: 'row' }} justify='flex-end' align='center' gap='4' py='4' px={{ base: '4', md: '20' }} w='full' bg='gray.50' shadow='dark-lg'>
                    <Image src={logo} h='28' objectFit='cover' marginInlineEnd='auto' />
                    {/* <Heading marginInlineEnd='auto'>
                    NERVICOM
                </Heading> */}
                    <Flex display={{ base: 'none', md: 'flex' }} gap='8' as='nav' h='min-content'>
                        <ButtonGroup spacing='8' variant='ghost' colorScheme='brand' p='1px' rounded='lg' flexDir={{ base: 'column', md: 'row' }}>
                            {links.map(item => (
                                <Button as='a' href={item.link} textTransform='uppercase' rounded='md' >
                                    {t(item.title)}
                                </Button>
                            ))}
                        </ButtonGroup>
                        {/* <Menu>
                            <MenuButton as={Button} variant='ghost' colorScheme='brand' textTransform='uppercase' >
                                {t('language')}
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => i18n.changeLanguage('es')} >
                                    {t('spanish')}
                                </MenuItem>
                                <MenuItem onClick={() => i18n.changeLanguage('en')} >
                                    {t('english')}
                                </MenuItem>
                            </MenuList>
                        </Menu> */}
                    </Flex>
                    <IconButton ref={btnRef} display={{ base: 'flex', md: 'none' }} icon={<AiOutlineMenu />} variant='ghost' colorScheme='brand' textTransform='uppercase' onClick={onOpen} />
                    <Drawer
                        isOpen={isOpen}
                        placement='right'
                        onClose={onClose}
                        finalFocusRef={btnRef}
                    >
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader/>
                            <DrawerBody>
                                <Flex as='nav' h='min-content' direction='column' align='center' gap='4' >
                                    {links.map(item => (
                                        <Button as='a' href={item.link} textTransform='uppercase' variant='ghost' colorScheme='brand' rounded='lg' textAlign='center' >
                                            {t(item.title)}
                                        </Button>
                                    ))}
                                    <Menu>
                                        <MenuButton as={Button} variant='ghost' colorScheme='brand' textTransform='uppercase' >
                                            {t('language')}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => i18n.changeLanguage('es')} >
                                                {t('spanish')}
                                            </MenuItem>
                                            <MenuItem onClick={() => i18n.changeLanguage('en')} >
                                                {t('english')}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Flex>
            </Flex>
            <Box w='full' scrollBehavior='smooth'>
                {children}
            </Box>
            <Flex pos='relative' direction='column' w='full' bg='gray.100' shadow='lg' borderTop='1px solid' borderTopColor='brand' >
                <Flex zIndex='1' flexDir={{ base: 'column', md: 'row' }} align='center' justify='center' gap='12' py='8' px='20' h='full' w='full'>
                    {/* <Flex direction='column' flex='1' gap='2'>
                    <Heading size='xl' fontFamily='NewRoboto' >
                        Nervicom
                    </Heading>
                    <Text fontSize='lg'>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </Text>
                </Flex> */}
                    {/* <Image src={logo} h='24' objectFit='contain' flex='1' /> */}
                    {/* <Flex direction='column' flex='1' gap='2'>
                    <Heading size='lg' fontFamily='NewRoboto' >
                        {t('address')}
                    </Heading>
                    <Text fontSize='md'>
                        Venezuela
                    </Text>
                </Flex>
                <Flex direction='column' flex='1' gap='2'>
                    <Heading size='lg' fontFamily='NewRoboto' >
                        {t('phone')}
                    </Heading>
                    <Text fontSize='md'>
                        +58 123 4567890
                    </Text>
                </Flex> */}
                    <Flex direction='column' flex='1' gap='2' align='center'>
                        <Heading size='sm' fontFamily='NewRoboto' textAlign='center' >
                            {t('email')}
                        </Heading>
                        <Text fontSize='md'>
                            info@nervicom.net
                        </Text>
                        <Heading size='sm' fontFamily='NewRoboto' textAlign='center' mt='4' >
                            Copyright © 2024 Nervicom LLC
                        </Heading>
                    </Flex>
                    {/* <Flex direction='column' flex='1' gap='2' align='center'>
                        <Heading size='md' fontFamily='NewRoboto' textAlign='center' >
                            {t('social-networks')}
                        </Heading>
                        <Flex direction='row' gap='4'>
                            <Icon as={AiOutlineLinkedin} boxSize='8' />
                        </Flex>
                    </Flex> */}
                </Flex>
            </Flex>
            <Box as={ScrollToTop} smooth component={<Icon as={AiOutlineArrowUp} />} shadow='brand' border='2px solid' borderColor='brand' style={{ borderRadius: '0px' }} />
        </Flex>
    )
}

export default MainLayout