import { extendTheme } from "@chakra-ui/react";
import Button from "./Theme/Button";
import Tabs from "./Theme/Tabs";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
    brand: {
        900: "#000016",
        800: "#00001D",
        700: "#000024",
        600: '#00002C',
        500: '#000033',
        400: '#000075',
        300: '#0000B6',
        200: '#0000F8',
        100: '#3A3AFF',
        50: '#7C7CFF',
    },
    'brandNew': {
        900: "#702A06",
        800: "#953709",
        700: "#BA450B",
        600: '#DF530D',
        500: '#F26722',
        400: '#F47D41',
        300: '#F69261',
        200: '#F8A880',
        100: '#F9BEA0',
        50: '#FBD4C0',
    },
};

const theme = extendTheme({
    fonts: {
        body: "NewRoboto, system-ui, sans-serif",
        heading: "Brand, Georgia, serif"
    },
    colors,
    components: {
        Button,
        Tabs
    },
    shadows: {
        brand: '0 25px 50px -2px rgba(0, 0, 0, 0.5)'
    }
});

export default theme;

