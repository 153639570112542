import { Box, Button, Circle, Divider, Flex, FormControl, FormLabel, Heading, Icon, Image, Input, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text, Textarea, UnorderedList, useDisclosure } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { AiOutlineWhatsApp } from "react-icons/ai";
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import homeVideo from './home-video-05.mp4'
import miami from './images/miami-00.jpg'
import caracas from './images/caracas-02.jpg'
import lima from './images/lima-01.jpg'
import brasil from './images/saopaulo-00.jpg'
import map from './images/home-01.jpg'
import opt from './images/fibra-optica.png'
import net from './images/sitio-web.png'
import conect from './images/conexion.png'
import plan from './images/plan.png'
import { MdCheckCircle } from 'react-icons/md';

function App() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [indexService, setIndexService] = useState(0)

  const subs = [
    {
      country: 'Ciberseguridad',
      state: '',
      image: miami
    },
    {
      country: 'Conectividad Empresarial',
      state: '',
      image: caracas
    },
    {
      country: 'Precios bajos del mercado',
      state: '',
      image: lima
    },
  ]

  const services = [
    {
      title: 'Acceso Dedicado a Internet',
      description: 'Conexiones protegidas y de alta eficiencia por medio de una red de Fibra Óptica, con los principales proveedores Tier1 y Puntos de Intercambio de Internet.',
      image: opt,
      fullDescription: 'Conexiones protegidas y de alta eficiencia por medio de una red de Fibra Óptica, con los principales proveedores Tier1 y Puntos de Intercambio de Internet.',
      listDescription: [
        'Conexiones protegidas y de alta eficiencia por medio de una red de Fibra Óptica, con los principales proveedores Tier1 y Puntos de Intercambio de Internet',
        'Disponible en: Miami, Colombia, Perú, Bolivia, Brasil y Venezuela',
        'Servicio por Fibra Óptica diseñado para ISP’s y/o Carriers',
        'Incluye BGP IPv4/IPv6',
        'Ancho de banda fijo y simétrico',
        'Conexión las 24 horas, los 365 días del año'
      ]
    },
    {
      title: 'Enlace Dedicado a Internet',
      description: 'Radioenlaces flexibles en crecimiento, los cuales permiten de manera exclusiva conectar las sedes de EL CLIENTE, a través de nuestro Backbone.',
      image: net,
      fullDescription: 'Radioenlaces flexibles en crecimiento, los cuales permiten de manera exclusiva conectar las sedes de EL CLIENTE, a través de nuestro Backbone.',
      listDescription: [
        'Servicio Inalámbrico diseñado para Empresas, Corporaciones',
        'Impulsamos el desarrollo tecnológico de su empresa',
        'Ancho de banda fijo y simétrico',
        'Conexión las 24 horas, los 365 días del año'
      ]
    },
    {
      title: 'Bandwith Connectivity',
      description: 'Permiten la conexión de múltiples sedes bajo una red privada de datos, asegurando la seguridad de la información transmitida.',
      image: conect,
      fullDescription: 'Permiten la conexión de múltiples sedes bajo una red privada de datos, asegurando la seguridad de la información transmitida.',
      listDescription: [
        'Ideal para empresas con múltiples sucursales o Entidades Bancarias',
        'Ancho de banda fijo y simétrico',
        'Conexión las 24 horas, los 365 días del año'
      ]
    },
    {
      title: 'Planes Express',
      description: 'Conexión por Radioenlace o por fibra óptica.',
      image: plan,
      fullDescription: 'Conexiones protegidas y de alta eficiencia por medio de una red de Fibra Óptica, con los principales proveedores Tier1 y Puntos de Intercambio de Internet.',
      listDescription: [
        'Conexión por Radioenlace o por fibra óptica',
        'Diseñados para el hogar y comercios',
        'Superior en calidad a los planes Residenciales de terceros',
        'Conexión las 24 horas, los 365 días del año',
        'Ancho de banda variable'
      ]
    },
  ]

  const handleClickService = (i) => {
    setIndexService(i)
    onOpen()
  }

  return (
    <>
      <Flex direction='column' gap='6' minH='80vh' id='home' >
        <Flex flexDir='column' w='full' h='80vh' pos='relative' justify='center' align='flex-start' px='15vw' shadow='0 5px 50px -2px rgba(0, 0, 0, 0.40)'>
          <Box as='video' pos='absolute' inset='0' h='full' w='full' /* src={behind} */ src={homeVideo} objectFit='cover' autoPlay={true} muted={true} loop={true} />
          <Box pos='absolute' inset='0' h='full' w='full' bg='blackAlpha.500' />
          <Heading zIndex='1' color='white' textTransform='uppercase' size='3xl' >
            {t('title').split(' ').map((word, index) => (
              <>
                <Box key={index} as={motion.span} initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: index * 0.2 }} display='inline-block' color={['conéctate', 'mundo', 'nervicom', 'connect', 'world'].includes(word.replace(',', '').toLowerCase()) ? 'brandNew.100' : 'white' } fontFamily={['conéctate', 'mundo', 'nervicom', 'connect', 'world'].includes(word.replace(',', '').toLowerCase()) ? 'NewRoboto' : 'inherit'} fontStyle={['conéctate', 'mundo', 'nervicom', 'connect', 'world'].includes(word.replace(',', '').toLowerCase()) ? 'italic' : 'normal'} >
                  {['conéctate', 'mundo', 'nervicom', 'connect', 'world'].includes(word.replace(',', '').toLowerCase()) && word.includes(',') ? word.replace(',', '') : word}
                </Box>
                {['conéctate', 'mundo', 'nervicom', 'connect', 'world'].includes(word.replace(',', '').toLowerCase()) && word.includes(',') ? ',' : ''}
                {' '}
              </>
            ))}
          </Heading>
        </Flex>
      </Flex>
      <Flex position='relative' direction='column' h={{ base: 'auto', md: 'auto' }} p='24' px={{ base: '8', md: '48' }} gap='12' id='beneficios' >
        <Flex as={motion.div} initial={{ opacity: 0, y: '-100%' }} whileInView={{ opacity: 1, y: 0 }} align='center' >
          <Divider borderColor='brandNew' orientation='horizontal'/>
          <Heading w='full' textAlign='center' size='2xl' textTransform='uppercase' fontWeight='black' fontStyle='italic' fontFamily='NewRoboto' color='brandNew'>Beneficios</Heading>
          <Divider borderColor='brandNew' orientation='horizontal'/>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 3, '2xl': 3 }} gap='12' h='full' flexWrap='wrap' zIndex='1' >
          {
            subs.map((s, i) => (
              <Flex key={i} as={motion.div} initial='initial' animate='animate' whileHover='hover' whileTap='hover'  variants={{ 'initial': { scale: 1 }, 'hover': { scale: 1.1 }}} direction='column' pos='relative' justify='flex-start' align='center' p='8' overflow='hidden' zIndex='1' shadow='brand' rounded='30px' gap='4'>
                <Box h='20' w='20' pos='relative' rounded='full' >
                  <Box as='img' src={s.image} pos='absolute' inset='0' h='full' w='full' rounded='full' />
                </Box>
                <Heading as={motion.h4} pos='relative' size={{ base: 'lg', md: 'lg', '2xl': 'lg' }} zIndex='2' fontFamily='NewRoboto' textTransform='uppercase' textAlign='center' >
                  {s.country}
                  <Box as={motion.div} variants={{ initial: { scaleX: 0 }, hover: { scaleX: 1 } }} pos='absolute' bottom='0' h='2px' w='full' bg='white' zIndex='2' scaleX='0' />
                </Heading>
                <Text textAlign='center' fontSize='md' zIndex='2' >{s.state}</Text>
              </Flex>
            ))
          }
        </SimpleGrid>
        <Circle h='48' aspectRatio='1/1' pos='absolute' bottom='0' right='-8' bg='var(--chakra-colors-brand-500)' filter='blur(500px)' zIndex='-1' />
        <Circle h='48' aspectRatio='1/1' pos='absolute' top='0' left='-8' bg='var(--chakra-colors-brandNew-500)' filter='blur(500px)' zIndex='-1' />
      </Flex>
      <Flex pos='relative' direction='column' minH='100vh' h={{ base: 'auto', md: 'auto' }} p='24' px={{ base: '8', md: '48' }} gap='12' /* paddingTop='32' */ id='about-us' >
        <Flex as={motion.div} initial={{ opacity: 0, y: '-100%' }} whileInView={{ opacity: 1, y: 0 }} align='center' >
          <Divider borderColor='brandNew' orientation='horizontal'/>
          <Heading w='full' textAlign='center' size='2xl' textTransform='uppercase' fontWeight='black' fontStyle='italic' fontFamily='NewRoboto' color='brandNew'>{t('about-us')}</Heading>
          <Divider borderColor='brandNew' orientation='horizontal'/>
        </Flex>
        <Flex flex='1' direction={{ base: 'column', md: 'row' }} gap='12'>
          <Image src={map} flex='0.7' w='440px' minH={{ base: 'md', lg: '2xl' }} h='full' shadow='brand' rounded='30px' bg='white' objectFit='cover' />
          <Flex bg='white' shadow='brand' p='8' rounded='30px' minH='2xl' h='full' >
            <Text fontSize='lg' fontWeight='500'>
              {/* <Text as='p' fontSize='2xl' >{t('nap')}</Text> */}
              Somos un OPERADOR NEUTRAL en el mercado mayorista de las Telecomunicaciones. 
              <br></br>Proveemos servicios de Acceso a Internet a través de diversas plataformas tecnológicas que se adaptan a las necesidades específicas de nuestros clientes.
              <br></br>
              <br></br>Contamos con alianzas estratégicas junto a las principales Operadoras de Cable Submarino y proveedores de Fibra Óptica Terrestre en diversas regiones. Esto nos posiciona para brindar a nuestros clientes servicios de alta capacidad, precios competitivos, mínima latencia, y los más altos estándares de seguridad y eficiencia.
              <br></br>
              <br></br>
              <UnorderedList>
                <ListItem><strong>Innovadores en tecnología:</strong> Desarrollamos soluciones que impulsan el crecimiento de su empresa o negocio.</ListItem>
                <ListItem><strong>Compromiso con la excelencia:</strong> Entregamos servicios de la más alta calidad, asegurando la satisfacción total de nuestros clientes.</ListItem>
                <ListItem><strong>Equipo de expertos:</strong> Contamos con un equipo de profesionales altamente calificados.</ListItem>
                <ListItem><strong>Visión a futuro:</strong> En Nervicom, nos anticipamos ante las necesidades del mercado para ofrecer soluciones innovadoras que definan la próxima generación de la tecnología.</ListItem>
                <ListItem><strong>Grandes Mayoristas:</strong> precios más bajos del mercado.</ListItem>
                <ListItem><strong>Operador Neutral:</strong> Forjamos alianzas. No competimos con nuestros clientes.</ListItem>
              </UnorderedList>
            </Text>
          </Flex>
        </Flex>
        <Circle h='48' aspectRatio='1/1' pos='absolute' bottom='0' left='-8' bg='var(--chakra-colors-brandNew-500)' filter='blur(750px)' zIndex='-1' />
      </Flex>
      <Box minH='100vh' pos='relative' id='services' >
        <Box pos='absolute' h='full' w='full' inset='0' overflow='hidden'>
          <Box as={motion.img} initial={{ scale: 1, transition: { duration: 10, repeat: Infinity, repeatType: 'mirror' } }} animate={{ scale: 1.1, transition: { duration: 10, repeat: Infinity, repeatType: 'mirror' } }} src={caracas} backgroundImage={caracas} pos='absolute' h='full' w='full' inset='0' objectFit='cover' backgroundAttachment='fixed' backgroundSize='cover' backgroundPosition='center' backgroundRepeat='no-repeat' filter='blur(5px) saturate(1.5)' opacity='0.5' />
        </Box>
        <Flex bg='blackAlpha.600' direction='column' minH='100vh' zIndex='2' gap='2'>
          <Flex as={motion.div} initial={{ opacity: 0, y: '-100%' }} whileInView={{ opacity: 1, y: 0 }} align='center' marginTop='24' mx='24'>
            <Divider borderColor='white' orientation='horizontal'/>
            <Heading color='white' zIndex='1' w='full' textAlign='center' size='2xl' textTransform='uppercase' fontWeight='black' fontStyle='italic' fontFamily='NewRoboto' >{t('services')}</Heading>
            <Divider borderColor='white' orientation='horizontal'/>
          </Flex>
          {/* <Text color='white' zIndex='1' w='full' textAlign='center' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, voluptates.</Text> */}
          <SimpleGrid zIndex='2' w='full' columns={2} spacing='12' p='24' >
            {services.map((service, index) => (
              <Box as={motion.div} initial={{ scale: 1 }} whileHover={{ scale: 1.1 }} zIndex='1' pos='relative' w='full' h='full' /* minH='420px' */ overflow='hidden' shadow='brand' rounded='30px' >
                <Flex pos='relative' zIndex='3' w='full' h='full' bg='whiteAlpha.900' direction='column' gap='4' p='8' /* py='20' */ py='12' justify='flex-start' align='flex-start' backdropFilter='blur(70px)' onClick={() => handleClickService(index)}>
                  <Box as='img' src={service.image} h='20' w='20' />
                  <Heading zIndex='4' fontFamily='NewRoboto, system-ui, sans-serif' textAlign='start'>
                    {service.title}
                  </Heading>
                  <Text zIndex='4' textAlign='start' >
                    {service.description}
                  </Text>
                  <Circle pos='absolute' right='-10%' top='0' aspectRatio='1/1' h='full' bg='brandNew.100' filter='blur(200px)' />
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
          <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl' >
            <ModalOverlay backdropFilter='blur(5px)' />
            <ModalContent>
              <ModalHeader fontSize='2xl' >{services[indexService].title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody as={Flex} direction='column' gap='4' p='0' minH='35vh'>
                <Text fontSize='lg' px='6' >
                  {services[indexService].fullDescription}
                </Text>
                <Divider/>
                <List spacing='2' fontSize='lg' bg='brand.500' color='white' p='8' rounded='md' marginTop='auto' >
                  {services[indexService].listDescription.map((v, i) => (
                    <ListItem>
                      <ListIcon as={MdCheckCircle} color='green.300' />
                      {v}
                    </ListItem>
                  ))}
                </List>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      </Box>
      <Flex direction='column' minH='80vh' h={{ base: 'auto', md: 'auto' }} py='24' gap='12' id='contact' >
          <Flex as={motion.div} initial={{ opacity: 0, y: '-100%' }} whileInView={{ opacity: 1, y: 0 }} align='center' mx='24'>
            <Divider borderColor='brandNew' orientation='horizontal'/>
            <Heading color='brandNew' zIndex='1' w='full' textAlign='center' size='2xl' textTransform='uppercase' fontWeight='black' fontStyle='italic' fontFamily='NewRoboto' >{t('contact')}</Heading>
            <Divider borderColor='brandNew' orientation='horizontal'/>
          </Flex>
        <Flex direction={{ base: 'column', md: 'row' }} gap='12' mx={{ base: '8', md: '24' }} >
          <Flex direction='column' gap='4' flex='1 0 40vw' shadow='brand' rounded='md' bg='white' p='8' >
            <FormControl>
              <FormLabel>
                {t('name')}
              </FormLabel>
              <Input colorScheme='brand' border='2px solid' borderColor='brand' rounded='none' />
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('subject')}
              </FormLabel>
              <Input colorScheme='brand' border='2px solid' borderColor='brand' rounded='none'/>
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('message')}
              </FormLabel>
              <Textarea maxH='200px' colorScheme='brand' border='2px solid' borderColor='brand' rounded='none'/>
            </FormControl>
            <Button colorScheme='brand' rounded='none'>
              {t('send')}
            </Button>
          </Flex>
          <Flex direction='column' gap='4' flex='1 0 40vw' shadow='brand' rounded='md' bg='white' p='8'>
            <Heading fontFamily='NewRoboto' >
              Whatsapp
            </Heading>
            <Text>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum cupiditate consequuntur facilis.</Text>
            <Flex gap='4' align='center'>
              <Icon as={AiOutlineWhatsApp} boxSize='12'/>
              <Text fontSize='lg' >
                +58 123 4567890
              </Text>
            </Flex>
          </Flex>
        </Flex>
    </Flex>
    </>
  )
}

export default App;
